#map {
  height: calc(100dvh - 70px);
}

.legend {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: white;
  padding: 10px;
  padding-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  z-index: 1000;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.legend-item {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.legend-color {
  margin-right: 7px;
  margin-left: 3px;
  border-radius: 50%;
  color: white;
}

.currently-cycling-container {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 800px) {
  .legend {
    bottom: 10px;
    left: 10px;
    max-width: 50%;
  }
}

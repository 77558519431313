.trip-text-section {
  margin-top: 30px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100dvh - 100px);
  margin-left: 7px;
}
.route-header {
  display: flex;
  flex-direction: row;
  margin-left: 23px;
  justify-content: space-between;
  width: 87%;
  /* border-bottom: 1px solid #b2b2b2; */
}
.route-name-heading {
  font-size: large;
  font-weight: 600;
}

.total-length {
  color: #94722e;
  font-size: small;
  border: 1px solid rgb(148, 114, 46);
  border-radius: 5px;
  padding: 3px 10px;
  margin-bottom: 10px;
  background-color: #ffeabf;
}

.trip-details-container {
  width: 100%;
  border-bottom: 1px solid #b2b2b2;
  display: flex;
  border-image: linear-gradient(to right, transparent 8%, #b2b2b2 8%, #b2b2b2 92%, transparent 92%)
    1;
  cursor: pointer;
}
/* .trip-details-container:hover {
  background-color: rgb(239, 238, 238);
  width: 100%;
} */

/* .trip-details-container.active {
  background-color: rgb(239, 238, 238);
} */

.trip-details {
  width: 100%;
  flex-direction: column;
  display: flex;
  height: 100%;
  overflow: auto;
}

.trip-details-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
}

.trip-details-body {
  width: 100%;
}

.line-circle {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 5px;
}
.circle {
  position: absolute;
  top: 21px;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  min-height: 15px;
  min-width: 15px;
  z-index: 10;
  color: white;
  font-size: 12px;
}

.line-div {
  height: 100%;
  width: 3px;
  min-height: 50px;
  margin-right: 10px;
  margin-left: 10px;
  top: 26px;
  position: relative;
}
.route-name {
  font-size: small;
  font-weight: 600;
  margin-bottom: 0;
}
.date {
  color: gray;
  font-size: 12px;
}

.status-container {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  background-color: #f6f6f6;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 5px;
}

.status {
  margin: 0;
  padding: 5px 10px;
}

.bulletin {
  padding-left: 5px;
  font-size: 6px;
  margin-bottom: 0px;
}

.length {
  color: #94722e;
  font-size: small;
  border: 1px solid rgb(148, 114, 46);
  border-radius: 5px;
  padding: 3px 10px;
  height: 26px;
  margin-right: 15px;
  margin-top: 3px;
}

.thumbnail-container {
  margin: 20px 0px;
  display: flex;
}

.thumb-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 104px;
  height: 104px;
  background-color: rgba(0, 0, 0, 0.3); /* Transparent white overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: rgba(255, 255, 255, 0.8);
  margin: 2px;
  border-radius: 10px;
}

.thumb {
  object-fit: cover;
  width: 104px;
  height: 104px;
  margin: 2px;
  border-radius: 10px;
}

.progress-km {
  width: 88%;
  margin-left: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
}

.percentage {
  margin-bottom: 0;
  font-size: small;
  margin-left: 5px;
  color: gray;
}
.progress {
  width: 100%;
}

.progress-bar {
  background-color: #6b92b2 !important;
}

@media (max-width: 800px) {
  .trip-text-section {
    width: 100%;
  }

  .length {
    margin-right: 30px;
  }
}
@media (max-width: 380px) {
  .thumb-overlay {
    width: 90px;
    height: 90px;
  }
  .thumb {
    width: 90px;
    height: 90px;
  }
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  border-bottom: #b8b8b8;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: #ffffff;
  /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4); /* Shadow effect */
}
.nav-logo-container {
  margin-left: 5%;
}
.navbar-links-container {
  margin-right: 10%;
  margin-left: 50px;
}

.navbar-links-container a {
  margin-right: 1rem;
  padding: 5px 10px;
  text-decoration: none;
  color: #222222;
  font-size: 1.1rem;
}

.navbar-links-container a.active {
  font-weight: bold;
  border-bottom-style: solid;
  border-image: linear-gradient(
      to right,
      transparent 25%,
      #94722e 25%,
      #94722e 75%,
      transparent 75%
    )
    1;
}

.navbar-links-container a:hover {
  border-image: linear-gradient(
      to right,
      transparent 25%,
      #94722e 25%,
      #94722e 75%,
      transparent 75%
    )
    1;
}

.navbar-menu-container {
  display: none;
}

.buymeacoffee {
  margin-left: 5px;
}

@media (max-width: 800px) {
  .navbar-menu-container {
    display: flex;
    font-size: 35px;
    padding-right: 5px;
  }
  .navbar-links-container {
    display: none;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

.blue-background {
  background-color: #6b92b2;
}

.gold-background {
  background-color: #93712d;
}
.red-background {
  background-color: #ea4335;
}

.gold-font {
  color: #93712d;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100dvh;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}

.map-section {
  flex: 1;
}

.flex {
  display: flex;
}

.statuses {
  width: 90%;
}

.custom-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  color: white;
  z-index: 10;
}

.gold-dot-icon {
  background-color: #93712d;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  border-color: white;
  border-style: solid;
  border-width: 1px;
}

.blue-dot-icon {
  background-color: #6b92b2;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  border-color: white;
  border-style: solid;
  border-width: 1px;
}
.leaflet-popup {
  padding-bottom: 30px;
}
.column {
  flex: 1;
  margin-right: 40px;
}
.label {
  font-size: clamp(0.7rem, 1vw, 1.5rem);
  color: #797979;
  margin-bottom: 0;
}
.value {
  font-size: clamp(1rem, 2vw, 3rem);
  color: #4c4c4c;
  font-weight: bold;
}

.bottom-right-button:hover {
  background-color: #e48f0f;
}

.loading-container {
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

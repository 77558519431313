.ongoing-trip-container {
  display: flex;
  flex-direction: row;
}

.button-switch {
  display: none;
}

@media (max-width: 800px) {
  .button-switch {
    display: inline;
    position: absolute;
    bottom: 25px;
    right: 10px;
    color: white;
    background-color: #9b7b3c;
    padding: 14px;
    border-radius: 20px;
    border-color: #9b7b3c;
    box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    z-index: 1000;
    max-width: 45%;
  }

  .none {
    display: none;
  }

  .map-section {
    width: 400px;
    flex: 1;
  }
}

@media (max-width: 320px) {
  .button-switch {
    font-size: small;
  }
}
